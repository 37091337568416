<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">METHODOLOGIES</div>
    </div>
    <div class="page_content">
      <div class="page_methods" :class="{'full_page': right_bar}">
        <h4 class="page_subtitle">Model's name</h4>

        <div class="page_title-container">
          <h3 class="page_title" v-if="!titleStatus">{{ modelData.name }}</h3>
          <b-form-input v-else v-model="modelData.name"></b-form-input>
          <div v-if="!titleStatus" class="edit_btn" @click="changeTitle()"></div>
          <div v-else @click="changeTitle()" class="change_btn">Change</div>
        </div>

        <div class="goals" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header class="p-0" role="tab">
              <b-button block v-b-toggle.accord_2 variant="" class="goal_btn">Goals and infos
                <div class="arrow_down"></div>
              </b-button>
            </b-card-header>
            <b-collapse id="accord_2" accordion="my-accordion" role="tabpanel" class="collapse_panel">
              <b-card-body>
                <b-card-text>
                  <div class="w-100">

                    <div class="input_item">
                      <label class="collapse_panel-text">Methodological Description</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.methodological_description"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Teachers and Students Roles</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.teachers_and_students_roles"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Class Settings</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.class_settings"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Evaluation Methods</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.evaluation_methods"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Needed Tools </label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.tools_needed"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Criticality and Possible Solutions</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.criticality_and_possible_solutions"
                      >
                      </b-form-input>
                    </div>
                    <div class="input_item">
                      <label class="collapse_panel-text">Extra Content</label>
                      <b-form-input
                          class="collapse_panel-input"
                          v-model="modelData.extra_content"
                      >
                      </b-form-input>
                    </div>


                  </div>

                  <!--                  <span class="collapse_panel-text">What went good?</span>-->
                  <!--                  <b-form-input v-model="efficiency_and_result.good"-->
                  <!--                                class="collapse_panel-input"></b-form-input>-->
                  <!--                  <span class="collapse_panel-text">What can i improve?</span>-->
                  <!--                  <b-form-input v-model="efficiency_and_result.improve"-->
                  <!--                                class="collapse_panel-input"></b-form-input>-->
                  <!--                  <span class="collapse_panel-text">Notes:</span>-->
                  <!--                  <b-form-input v-model="efficiency_and_result.notes"-->
                  <!--                                class="collapse_panel-input"></b-form-input>-->
                  <!--                  <div class="add_btn-container">-->
                  <!--                    <b-button class="add_btn" v-b-toggle.accord_2>Add</b-button>-->
                  <!--                  </div>-->
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="model_btn-container">
          <b-button class="model_btn" @click="createMethod()">{{ btn_text }}</b-button>
          <div class="success_msg" v-if="success_message">{{ success_message }}</div>
        </div>
      </div>
      <div class="page_right-bar" :class="{'open_bar': right_bar}">
        <div class="bar_btn" @click="openBar()">
          <svg v-if="right_bar" width="24" height="111" viewBox="0 0 24 111" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.3998 110H5.7998C3.2998 110 1.2998 108 1.2998 105.5V5.80005C1.2998 3.30005 3.2998 1.30005 5.7998 1.30005H18.3998C20.8998 1.30005 22.8998 3.30005 22.8998 5.80005V105.5C22.8998 108 20.8998 110 18.3998 110Z"
                stroke="#97D5C9" stroke-width="0.8109" stroke-miterlimit="10"/>
            <path d="M16.9 71.7001L6.5 55.8L17 39.8" stroke="#97D5C9" stroke-width="0.4417"
                  stroke-miterlimit="10"/>
          </svg>

          <svg v-else width="22" height="110" viewBox="0 0 22 110" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.0002 0.5H16.4002C18.8002 0.5 20.7002 2.39999 20.7002 4.79999V104.8C20.7002 107.2 18.8002 109.1 16.4002 109.1H5.0002C2.6002 109.1 0.700195 107.2 0.700195 104.8V4.79999C0.700195 2.49999 2.6002 0.5 5.0002 0.5Z"
                stroke="#97D5C9" stroke-width="0.7835" stroke-miterlimit="10"/>
            <path d="M6.2002 38.9L16.0002 54.8L6.2002 70.8" stroke="#97D5C9" stroke-width="0.4267"
                  stroke-miterlimit="10"/>
          </svg>
        </div>
        <div class="didactic_content" :class="{'hide_content': right_bar}">
          <div class="didactic_title">METHODOLOGIES AND CONTENTS</div>
          <div class="didactic_search">
            <label>Search on:</label>
            <b-form-group class="search_radios">
              <b-form-radio-group
                  class="radio_group"
                  id="radio-group-1"
                  v-model="selected_search"
                  :options="options"
                  name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="search_input-container">
            <b-form-input
                v-model="search_data"
            >
            </b-form-input>
            <div class="search_icon"></div>
          </div>
          <div class="educative_title">Methodologies</div>
          <div class="models_container">
            <div class="model_item" @click="openEdit(model, 'edit')" v-for="model in allMethodologies">
              {{ model.name }}
              <div class="remove_btn" @click.stop="removeMethod(model.id)"></div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AdminMethodologies",
  data() {
    return {
      right_bar: true,
      titleStatus: false,
      selected_search: 'chatGPT',
      options: [
        {text: 'chatGPT', value: 'chatGPT'},
        {text: 'Google', value: 'Google'},
      ],
      search_data: '',
      allMethodologies: [],
      btn_text: 'Save Methodology',
      modelData: {
        name: 'Model’s title example',
        methodological_description: null,
        teachers_and_students_roles: null,
        class_settings: null,
        evaluation_methods: null,
        tools_needed: null,
        criticality_and_possible_solutions: null,
        extra_content: null,
        id: null
      },
      success_message: ''
    }
  },
  created() {
    this.getAdminMethodologies()
  },
  methods: {
    ...mapActions(['methodologiesGet', 'createNewMethodology', 'methodologyDelete']),
    getAdminMethodologies() {
      this.methodologiesGet()
          .then(res => {
            this.allMethodologies = res.data
          })
    },
    changeTitle() {
      this.titleStatus = !this.titleStatus
    },
    openBar() {
      this.right_bar = !this.right_bar
    },
    createMethod() {
      if (this.btn_text == 'Cancel') {
        this.modelData.name = 'Model’s title example'
        this.modelData.methodological_description = null
        this.modelData.teachers_and_students_roles = null
        this.modelData.class_settings = null
        this.modelData.evaluation_methods = null
        this.modelData.tools_needed = null
        this.modelData.criticality_and_possible_solutions = null
        this.modelData.extra_content = null
        this.btn_text = 'Save Methodology'
      } else {
        this.createNewMethodology(this.modelData)
            .then(res => {
              if (res) {
                this.success_message = res.data.message
                setTimeout(() => {
                  this.success_message = ''
                }, 3000)
                this.modelData.name = 'Model’s title example'
                this.modelData.methodological_description = null
                this.modelData.teachers_and_students_roles = null
                this.modelData.class_settings = null
                this.modelData.evaluation_methods = null
                this.modelData.tools_needed = null
                this.modelData.criticality_and_possible_solutions = null
                this.modelData.extra_content = null
                this.getAdminMethodologies()
              }
            })
      }

    },
    removeMethod(id) {
      this.methodologyDelete(id)
          .then(res => {
            if (res) {
              this.getAdminMethodologies()
            }
          })
    },
    openEdit(model, status) {
      let el = document.getElementById('accord_2')
      if(el.classList.value.includes('show')) {

      } else {
        el.classList.add('show')
        el.style.display = "block"

      }
      if (status == 'edit') {
        this.btn_text = 'Cancel'
      }
      this.modelData.methodological_description = model.methodological_description
      this.modelData.name = model.name
      this.modelData.teachers_and_students_roles = model.teachers_and_students_roles
      this.modelData.class_settings = model.class_settings
      this.modelData.evaluation_methods = model.evaluation_methods
      this.modelData.tools_needed = model.tools_needed
      this.modelData.criticality_and_possible_solutions = model.criticality_and_possible_solutions
      this.modelData.extra_content = model.extra_content
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    width: 100%;
  }

  &_head {
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 3;

    &-title {
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &_content {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }

  &_methods {
    width: 100%;
    margin-right: 780px;
    transition: 0.6s;
    padding: 40px 60px;
    @media screen and (max-width: 1680px) {
      margin-right: 700px;
    }

    &.full_page {
      transition: 0.6s;
      margin-right: 100px;
    }

    & .goals {
      max-width: 960px;
      padding: 15px 0;

      & .card {
        border-radius: 7px;
        border: 1px solid #11334D;

        & .card-header {
          border-bottom: none;
        }

        & .collapse_panel {
          border-radius: 8px;
          background: #F3FAF8;
          position: relative;

          & .input_item {
            width: 100%;
          }

          &-text {
            color: #11334D;
            font-size: 18px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
          }

          &-input {
            width: 100%;
            margin-bottom: 15px;
            border-radius: 8px;
            border: 1px solid #11334D;
            height: 44px !important;
          }
        }
      }

      & .goal_btn {
        color: #11334D;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      & .arrow_down {
        width: 31px;
        height: 16px;
        background: url("~@/assets/icons/arrow_down.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    & .input {
      &_item {
        width: 100%;
        margin: 10px 30px 10px 0;

        & label {
          color: #11334D;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        & textarea {
          border: 1px solid #11334D;
          border-radius: 12px;
          resize: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    & .model_btn {
      height: 44px;
      border-radius: 12px;
      border: 1px solid #FFFFFF;
      background: #00A99D;
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 30px;
      cursor: pointer;

      &-container {
        padding: 15px 0;
        display: flex;
        align-items: center;

        & .success_msg {
          padding: 0 15px;
          color: #00A99D;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  &_subtitle {
    color: #11334D;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 13px;
  }

  &_title {
    color: #11334D;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &-container {
      max-width: 960px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & input {
        max-width: 760px;
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #11334D;
      }

      & .change_btn {
        width: 140px;
        height: 40px;
        border-radius: 12px;
        border: 1px solid #FFFFFF;
        background: #00A99D;
        color: #FFFFFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      & .edit_btn {
        width: 29px;
        height: 32px;
        background: url("~@/assets/icons/edit_btn.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }
  }


  &_right-bar {
    position: fixed;
    right: 0;
    width: 780px;
    height: 100%;
    background: #00A99D;
    filter: drop-shadow(0px 5px 25px #004475);
    display: flex;
    align-items: center;
    transition: 0.6s;
    @media screen and (max-width: 1680px) {
      width: 700px;
    }

    & .bar_btn {
      cursor: pointer;
      margin-left: 14px;
    }

    &.open_bar {
      transition: 0.6s;
      width: 100px;
    }

    & .didactic {
      &_content {
        padding: 50px 40px 20px 22px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        & .models_container {
          padding: 30px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          & .model_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 70px;
            border-radius: 8px;
            background: #ffffff;
            color: #11334D;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 10px 20px;
            margin: 10px 0;
            position: relative;
            @media screen and (max-width: 1680px) {
              width: 250px;
            }

            &:hover {
              & .remove_btn {
                transition: .4s;
                visibility: visible;
                opacity: 1;
              }
            }

            & .remove_btn {
              position: absolute;
              top: 10px;
              right: 10px;
              width: 20px;
              height: 20px;
              background: url("~@/assets/icons/deactive.svg") no-repeat center;
              background-size: cover;
              cursor: pointer;
              transition: .4s;
              visibility: hidden;
              opacity: 0;
            }
          }
        }

        &.hide_content {
          display: none;
        }

        & .search {
          &_input {
            &-container {
              width: 100%;
              position: relative;

              & input {
                height: 54px;
                border-radius: 8px;
                border: 2px solid #11334D;
                padding: 0 70px 0 20px;

                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }

          &_icon {
            position: absolute;
            right: 20px;
            top: 10px;
            width: 36px;
            height: 36px;
            background: url("~@/assets/icons/green_loop.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

          }
        }

        & .educative {
          &_title {
            text-align: center;
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 50px 0 10px;
          }
        }
      }

      &_title {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 20px;
      }

      &_search {
        /*padding: ;*/
        padding: 20px 0;
        display: flex;
        width: 100%;
        color: #11334D;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;

        & .search {
          &_radios {
            display: flex;

            & div {
              display: flex;
            }

            ::v-deep .custom-radio {
              padding: 0 50px !important;
              display: flex;
              align-items: center;

              & label {
                margin-left: 10px;
                color: #11334D;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              input[type="radio" i] {
                width: 20px;
                height: 20px;
              }
            }
          }

        }
      }

    }
  }
}
</style>